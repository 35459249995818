<template>
  <a-week-picker
    v-bind="record.options"
    :value="value"
    :value-format="valueFormat"
    :getCalendarContainer="record.options.getCalendarContainer || (triggerNode => triggerNode.parentNode)"
    @change="change"
    @openChange="openChange"
    @panelChange="panelChange"
  />
</template>

<script>
import formEleMixin from '../form-ele-mixin'
import mixin from './mixin'
export default {
  name: 'AxWeekPicker',
  mixins: [formEleMixin, mixin],
  data () {
    return {
      dateFormat: 'YYYY-WW'
    }
  }
}
</script>

<!--
未设置属性：
mode
open
-->
